@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.DealTeam {
  height: fit-content;
  max-width: 330px;
  min-width: 280px;
  padding: 20px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_768 {
    display: none;
  }

  &__heading {
    margin: 0;
    color: #8394a5;
    font: 500 30px $secondary-font;
  }

  &__list {
    @include displayFlex(null, null, column);
    gap: 25px;
    margin-top: 10px;
  }

  &__item {
    @include displayFlex(null, null, column);

    &_header {
      @include displayFlex(space-between, flex-start);
    }

    &:after {
      content: '';
      border: 1px solid #dde4e4;
      width: 100%;
      margin-top: 15px;
    }

    &:last-child:after {
      content: none;
    }
  }

  &__member {
    font-weight: 500;
    margin-bottom: 10px;

    &_role {
      font-style: italic;
      color: #848484;
    }
  }

  &__info {
    @include displayFlex(null, center);

    &_icon {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }

    &_link {
      color: $main-blue;
      font-size: 14px;
    }
  }
}
