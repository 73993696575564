@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.stepTextInput {
  &__label {
    color: $main-white;
    display: block;
    font: normal 600 14px/18px $main-font;
    grid-column: 1 / 4;
  }

  &__item {
    background: none;
    border: 1px solid $main-white;
    border-radius: 6px;
    box-sizing: border-box;
    color: $main-white;
    display: block;
    font-size: 14px;
    height: 42px;
    margin-top: 10px;
    outline: none;
    padding: 10px 12px;
    text-align: left;

    @include maxW_425 {
      width: 100%;
    }

    &::placeholder {
      color: $main-white;
    }
  }
}
