@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.removeShare {
  @include displayFlex(center, center, column);
  padding: 12px;
  height: 200px;
  gap: 25px;

  &__type {
    gap: 35px;
  }

  &__checkbox {
    & span {
      margin-right: -7px;
      font: 400 14px $main-font;
    }
  }
}

.footer {
  @include displayFlex(space-between);
  gap: 40px;
  margin-bottom: 40px;

  @include maxW_425 {
    width: 390px;
  }
}
