@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.second {
  @include displayFlex(center);
  flex-wrap: wrap;

  @include maxW_425 {
     @include displayFlex(null, null, column);
  }

  &__header {
    width: 100%;
    text-align: center;

     @include maxW_425 {
      text-align: left;
      // padding-bottom: 15px;
      margin-top: 55px;
    }
  }

  &__title {
    @include createNewDealTitle(36px);

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}
