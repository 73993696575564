@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.createNewDealWrapper {
  box-sizing: border-box;
  position: relative;
  @include displayFlex(null, null, column);
  min-height: 560px;
  width: 100%;
  max-width: 980px;
  padding: 80px 20px 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-image: url('/assets/images/create-project-bg.jpg');
  background-size: cover;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));

  @include maxW_425 {
    margin-top: 0;
    padding: 20px 10px;
    border-width: 1px 0;
    border-radius: 0;
  }

  &__sheetType {
    color: $quote-terms-text;

    @include maxW_375 {
      font-size: 13px;
    }
  }

  &__skip {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 15px;
    border-radius: 4px;
    color: $skip-button-text;
    background-color: $main-white;
    z-index: 5;

    @include maxW_425 {
      top: -5px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__skipText {
    font-family: $main-font;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: none;
  }

  &__content {
    @include displayFlex(center);
    flex: 1;
    min-width: 900px;

    @include maxW_425 {
      padding: 20px 0 50px;
    }
  }

  &__footer {
    @include displayFlex(space-between, center, column);
    max-height: 140px;
  }

  &__sliderWrapper {
    @include displayFlex(null, center, column);
    width: 100%;
    align-self: flex-end;
  }

  &__backBtn {
    @include maxW_425 {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__nextBtn {
    @include maxW_425 {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
