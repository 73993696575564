@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.GoogleMap {
  min-height: 300px;

  @include maxW_768 {
    @include displayFlex(center, center);
    min-height: 200px;
  }

  &__image {
    width: 100%;
    height: 100%;
    border: 1px solid $border;
    border-radius: 10px;

    @include maxW_768 {
      margin-top: 25px;
      width: 95%;
      height: 95%;
    }
  }
}

