@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.app {
  @include displayFlex(center);
  width: 100%;
  min-height: 100vh;

  &__content {
    width: 100%;
    max-width: 1400px;
  }

  &__loader {
    @include displayFlex(center, center);
    height: 100vh;
  }
}
