@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.Title {
  @include displayFlex(space-between, baseline);
}

.gallery {
  border-bottom: 1px solid $border;

  @include maxW_425 {
    display: none;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 10px 0 35px;
  }

  &__item {
    position: relative;
    max-height: 160px;
    min-height: 160px;
    width: 100%;
    border-radius: 10px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.1s ease-in;

    &:hover {
      transform: scale(1.05);
    }
  }
}
