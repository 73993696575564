@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Navbar {
  width: 100%;
  height: 100px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_425 {
    border: 0;
    height: 75px;
    position: fixed;
    z-index: 99;
    bottom: 0;
    border-radius: 0;
  }

  &__list {
    @include displayFlex(space-around, center);
    height: 100%;
  }

  &__item {
    @include displayFlex(null, center);
    color: $main-black;
    cursor: pointer;
    text-decoration: none;

    &__disabled {
      color: $main-gray;
      cursor: default;
      pointer-events: none;
    }

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__active {
    @include displayFlex(null, center);
    color: $main-blue;
    text-decoration: none;
  }

  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;

    &__reverse {
      transform: rotateZ(-90deg);
    }
  }
}
