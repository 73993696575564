@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.capitalSources {
  flex-grow: 1;
  min-height: 240px;
  padding: 0 20px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_425 {
    margin: 30px 0 60px 0;
    max-width: 95vw;
    padding: 0;
    border: none;
  }

  &__heading {
    margin: 15px 0 0;
    color: #8394a5;
    font: 500 30px $secondary-font;

    &_sub {
      color: #8394a5;
      font: 600 18px $main-font;
    }
  }

  &__reveal {
    @include displayFlex(space-between, center);
  }

  &__links {
    @include displayFlex();
    gap: 14px;
  }

  &__item {
    @include displayFlex(null, center);
    gap: 10px;
    width: 100%;
  }

  &__selected {
    height: 20px;
    width: 20px;
    margin: 0;
  }

  &__saveIcon {
    height: 20px;
    width: 20px;
    cursor: default;
  }

  &__name {
    margin-right: 5px;
    font-size: 18px;
  }

  &__type {
    font-weight: 300;
    flex-grow: 1;
  }

  &__status {
    font-weight: 300;
  }
}

.filter {
  &__disabled {
    @include maxW_425 {
      display: none;
    }
  }

  &__label {
    margin-top: 10px;
    color: #757575;
    font-size: 18px;

    @include maxW_425 {
      margin: 0;
    }
  }

  &__list {
    @include displayFlex();
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__item {
    flex-basis: 140px;
  }
}

.loader {
  @include displayFlex(center, center);
  min-height: 240px;
}

.sources {
  margin: 20px 0;

  @include maxW_425 {
    margin: 0;
  }

  &__actions {
    @include displayFlex(flex-start, center);
    gap: 38px;
  }
}

.details {
  @include displayFlex(null, null, column);
  gap: 20px;

  &__heading {
    color: $main-gray;
    font-style: italic;
  }

  &__body {
    font-size: 18px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__copyButton {
    width: fit-content;
    justify-content: flex-start;
    gap: 10px;
  }

  &__links {
    &__row {
      @include displayFlex(null, center);
      margin-top: 6px;
    }
  }

  &__link {
    &__icon {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }

    &__link {
      color: $main-blue;
    }
  }
}

.highlights {
  margin: 0;
  padding: 0 20px;

  &__item {
    &::marker {
      color: $light-blue;
    }
  }
}
