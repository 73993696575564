@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.tenants {
  @include displayFlex(null, center, column);

  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewDealTitle(36px);
    font-weight: 500;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__cards {
    margin-top: 50px;
    @include displayFlex(center);
    gap: 10px;
    flex-wrap: wrap;

    @include maxW_425 {
      flex-direction: column;
    }
  }

  &__form {
    width: 100%;
    margin-top: 30px;
  }

  &__tenantInput {
    @include displayFlex(center);
    max-width: 620px;
    width: 100%;
    padding-top: 30px;
    margin: 0 auto;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
}
