@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.signUp {
  box-sizing: border-box;
  @include displayFlex(center, center, column);
  height: 100vh;
  padding: 0 20px;
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(221, 228, 228, 1) 100%);

  &__container {
    height: fit-content;
    padding: 40px 80px;
    border: 1px solid $border;
    border-radius: 10px;
    background-color: $main-white;

    @include maxW_425 {
      height: 100%;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: $text-grey;
    font-size: 14px;
  }

  &__datePicker {
    width: 250px;
    height: 40px;
    padding: 0 14px;
    background: none;
    border: 1px solid $custom-input-border;
    border-radius: 6px;
    font: 500 14px $main-font;
    outline: none;
    color: $text-grey;
    text-transform: uppercase;
  }

  &__heading {
    margin-bottom: 10px;
    align-self: flex-start;
    font-size: 18px;
    font-weight: 600;
    color: $main-gray;
  }

  &__subtitle {
    font-size: 15px;
    font-weight: 500;
  }

  &__sublink {
    font-size: 15px;
    color: $main-blue;
    text-decoration: underline;
    margin-left: 3px;
    cursor: pointer;
  }

  &__link {
    font-size: 12px;
    color: $main-blue;
    text-decoration: underline;
  }
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
  margin-bottom: 10px;
  justify-content: center;

  &__field {
    width: 100%;
    margin-top: 24px;
  }

  &__actions {
    @include displayFlex(space-between, center);
    width: 100%;
    margin-top: 5px;
    font-size: 14px;

    &__container {
      @include displayFlex(null, center);
      color: $custom-input-label;
    }
  }

  &__password {
    color: rgb(20, 186, 133);
    font-weight: 600;
    margin-left: 3px;
  }

  &__checkbox {
    margin: 0;
    margin-right: 5px;
    padding: 0;
  }

  &__submit {
    width: 100%;
    margin-top: 24px !important;
  }
}

.header {
  @include displayFlex(center, center);
  margin-bottom: 30px;

  &__logo {
    width: 40px;
    height: 40px;

    @include maxW_425 {
      width: 30px;
      height: 30px;
      padding-left: 10px;
    }
  }

  &__title {
    margin-left: 10px;
    font-size: 32px;
    font-weight: 600;
    text-decoration: none;
    color: $main-black;

    @include maxW_425 {
      font-size: 28px;
    }
  }
}

.footer {
  @include displayFlex(null, null, column);
  gap: 24px;

  &__button {
    width: 100%;
  }

  &__policy {
    font-size: 12px;
  }
}
