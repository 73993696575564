@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.Slider {
  position: relative;
  min-height: 300px;

  @include maxW_425 {
    margin: 10px;
    min-height: 200px;
  }

  &.fullSize {
    min-height: 500px;
    max-width: 750px;
    margin: 10px auto 0;
  }

  &__arrowWrapper {
    position: absolute;
    top: 50%;
    @include displayFlex(center, center);
    z-index: 1;
    padding-top: 8px;
    height: 20px;
    width: 25px;
    cursor: pointer;

    &_left {
      left: 10px;
    }

    &_right {
      right: 10px;
    }
  }

  &__arrow {
    &:before,
    &:after {
      content: '';
      display: block;
      margin-top: -8px;
      height: 20px;
      border-right: 5px solid $main-white;
      transform: rotate(135deg);
      cursor: pointer;

      @include maxW_425 {
        top: 55%;
      }
    }

    &:after {
      transform: rotate(45deg);
    }

    &_back:before,
    &_back:after {
      left: 25px;
    }

    &_back:before {
      transform: rotate(-135deg);
    }

    &_back:after {
      transform: rotate(-45deg);
    }

    &_forward:before,
    &_forward:after {
      right: 25px;
    }

    &.fullSize {
      &:before,
      &:after {
        height: 35px;
        border-right: 7px solid $main-white;
        margin-top: -10px;
      }

      &:after {
        margin-top: -15px;
      }

      &.Slider__arrow_back:before,
      &.Slider__arrow_back:after {
        left: -75px;
      }

      &.Slider__arrow_forward:before,
      &.Slider__arrow_forward:after {
        right: -75px;
      }
    }
  }

  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    border-radius: 10px;
    transition: 1s;

    &.active {
      opacity: 1;
    }
  }

  &__dotsWrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 120px;
    overflow: hidden;

    &.fullSize {
      bottom: -50px;
      max-width: 305px;
    }

    @include maxW_425 {
      bottom: -20%;
    }
  }

  &__dots {
    display: flex;
    transition: 1s;

    &.center {
      justify-content: center;
    }
  }

  &__dot {
    min-width: 10px;
    height: 10px;
    border: 2px solid $main-white;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;

    &.fullSize {
      min-width: 18px;
      height: 18px;
      margin: 8px;
    }

    @include maxW_425 {
      border: 2px solid #848484;
    }

    &.active {
      background-color: $main-white;

      @include maxW_425 {
        border: 2px solid #4db79e;
        background-color: #4db79e;
      }
    }
  }
}
