@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Financial {
  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: $text-grey;
    font-size: 14px;
  }

  &__groupTitle {
    margin-bottom: 12px;
    color: $light-blue;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &__datePicker {
    height: 40px;
    padding: 0 14px;
    background: none;
    border: 1px solid $custom-input-border;
    border-radius: 6px;
    font: 500 14px $main-font;
    outline: none;
    color: $text-grey;
    text-transform: uppercase;
  }

  &__dateError {
    margin-top: 5px;
    font-size: 14px;
    color: red
  }

  &__amountDivider {
    margin: 0 8px;
  }

  &__groupBtn {
    border: 0;
    color: $main-blue;
    font-size: 14px;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__saved {
    margin-top: 20px;
    font-size: 16px;
    font-style: italic;
    color: $text-grey;
  }

  &__mobile {
    width: 290px;

    @include maxW_425 {
      width: 150px;
    }
  }

  &__postLabel {
    @include maxW_425 {
      margin-top: 12px;
    }
  }
}
