@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.thirteenth {
  @include displayFlex(null, center, column);
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;

  @include maxW_425 {
    align-items: flex-start;
  }

  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
      width: 100%;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    line-height: 54px;
    font-weight: 500;
    font-style: normal;
    color: $main-white;

    @include maxW_425 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      font-family: $main-font;
    }
  }

  &__subtitle {
    margin-top: 10px;
    margin-bottom: 22px;
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__inputsRow {
    margin-top: 12px;
    display: flex;
    width: 100%;

    @include maxW_580 {
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 0;
      width: auto;
    }

    @include maxW_425 {
      width: 100%;
    }
  }

  &__input {
    flex: 1;
    width: 100%;
    padding: 0 10px;

    @include maxW_580 {
      margin-top: 10px;
    }

    @include maxW_425 {
      width: auto;
    }
  }

  &__inputWide {
    min-width: 300px;

    @include maxW_768 {
      min-width: 280px;
    }
  }

  &__terms {
    margin-top: 28px;
    font-size: 14px;
    line-height: 18px;
    color: $main-white;

    @include maxW_425 {
      text-align: center;
    }
  }

  &__termsLink {
    color: $main-white;
  }

  &__saveBtn {
    display: inline-block;
    margin: 22px auto 42px;
    border-radius: 6px;
    background-color: $main-white;

    @include maxW_425 {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__saveText {
    font: normal 600 16px/20px $main-font;
    padding: 5px;
    text-align: center;
    color: $save-button-text;
    text-transform: none;
  }
}
