@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.fourth {
  width: 100%;

  &__header {
    width: 100%;
    text-align: center;

    @include maxW_425 {
      @include displayFlex(null, flex-start, column);
      width: 90%;
      margin-top: 75px;
      text-align: left;
    }
  }

  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__inputsContainer {
    @include displayFlex(null, center, column);
    margin: 10px auto;

    @include maxW_425 {
      align-items: unset;
    }
  }

  &__inputs {
    @include displayFlex(null, null, column);
    gap: 20px;
    padding: 25px 10px;
  }

  &__inputsRow {
    @include displayFlex(center);
    gap: 20px;
    margin-top: 20px;

    @include maxW_425 {
      @include displayFlex(null, null, column);
      margin-top: 0;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}
