@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.sourcesAndUses {
  &__tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include maxW_675 {
      grid-template-columns: 1fr;
      row-gap: 20px;
      margin: 0;
    }
  }
}

.table {
  display: flex;
  flex-direction: column;

  &__title {
    text-align: start;
    font-size: 16px;
    font-weight: 600;

    @include maxW_425 {
      border-bottom: none;
    }
  }

  &__headers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px dotted $border;
    color: $boulder-text;

    @include maxW_425 {
      border-bottom: none;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 5px 0;
  }

  &__cell {
    text-align: start;
    font-weight: 300;
    font-size: 13px;

    @include maxW_425 {
      text-align: center;
    }

    &-total {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 100%;
      align-items: flex-end;
      margin-top: 10px;
      padding: 0;
    }

    &-highlighted {
      font-weight: 500;
      color: $boulder-text;
      text-align: start;

      @include maxW_425 {
        font-size: 12px;
      }
    }

    &-bolded {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__sum {
    font-size: 20px;
    font-weight: 400;
    font-family: $main-font;
    letter-spacing: 0;
    color: $green-text;

    @include maxW_425 {
      line-height: 100%;
    }
  }
}
