@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.offers {
  width: 100%;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  &__container {
    display: block;
    align-items: center;
  }

  &__heading {
    margin: 0;
    margin-bottom: 10px;
    color: $main-gray;
    font-size: 18px;
  }

  &__title {
    display: block;
    color: $boulder-text;
    font-weight: 600;
    font-family: Source Sans Pro;
  }

  &__subtitle {
    color: $main-black;
    font-weight: 600;
    font-family: Source Sans Pro;
  }

  &__btnGroup {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0;
  }

  &__button {
    padding: 0;
    font: 600 normal 16px 'Source Sans Pro';
    background-color: $main-white;
    color: $main-blue;
    text-transform: none;
    border: none;
    cursor: pointer;
  }

  &__show {
    @include displayFlex(space-between, center);
    width: 100%;
    margin-top: 10px;
  }

  &__headingMore {
    color: $main-gray;
    font-size: 14px;
  }

  &__headingLess {
    color: $main-gray;
    font-size: 14px;
  }

  &__item {
    height: fit-content;
    max-width: 330px;
    min-width: 280px;
    padding: 20px;
    border: 1px solid #dde4e4;
    border-radius: 10px;
    background-color: #fff;
  }
}
