@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.eleventh {
  max-height: 216px;
  @include displayFlex(center);
  flex-wrap: wrap;

  @include maxW_425 {
    display: block;
    padding-left: 30px;
  }

  &__header {
    @include displayFlex(center, center, column);
    width: 740px;
    padding-bottom: 55px;

    @include maxW_425 {
      @include displayFlex(null, flex-start, column);
      width: 375px;
      margin-top: 65px;
      padding-bottom: 40px;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    color: $main-white;

    @include maxW_425 {
      font-family: $main-font;
      font-size: 24px;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }

  &__subtitle {
    font: normal normal 21px/26px $main-font;
    color: $main-white;
    text-align: center;

    @include maxW_425 {
      font-size: 14px;
      line-height: 18px;
      text-align: start;
      padding-left: 10px;
    }
  }

   &__cards {
    @include displayFlex(center);
    gap: 60px;

    @include maxW_425 {
      flex-direction: column;
      gap: 10px;
    }
  }
}
