@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.first {
  @include displayFlex(null, center, column);

  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewDealTitle(36px);
    font-weight: 500;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__cards {
    margin-top: 50px;
    @include displayFlex(center);
    gap: 10px;
    flex-wrap: wrap;

    @include maxW_425 {
      flex-direction: column;
    }
  }
}
