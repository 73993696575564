@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.questionMark {
  display: inline-block;
  visibility: hidden;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5999D8;
  display: flex;
  color: #fff;
  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 600;
    margin: auto;
  }
}
