@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.selectionCard {
  box-sizing: border-box;
  @include displayFlex(center, center, column);
  gap: 5px;
  height: 120px;
  min-width: 160px;
  color: $main-white;
  cursor: pointer;

  &__selected {
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
  }

  &:hover {
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
  }

  @include maxW_425 {
    @include displayFlex(flex-start, center, row);
    gap: 10px;
    width: 300px;
    height: 44px;
    padding-left: 10px;
  }

  &__top {
    @include displayFlex(null, center);
    flex: 0.5;

    @include maxW_425 {
      flex: none;
    }
  }

  &__title {
    display: block;
    text-align: center;
    font: normal normal 16px/20px $main-font;

    @include maxW_425 {
      font: normal normal 18px/23px $main-font;
      text-align: left;
    }
  }

  &__icon {
    @include maxW_425 {
      height: 20px;
      width: 20px;
    }
  }
}
