@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.usersPanel {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0 ;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_425 {
    border-radius: unset;
  }

  &__users {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @include maxW_425 {
      display: grid;
      row-gap: 25px;
    }
  }
}

.questions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @include maxW_425 {
    display: none;
  }

  &__title {
    font-size: 30px;
    margin-right: 30px;
    color: $boulder-text;
  }

  &__send {
    text-transform: none;
  }
}
