@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.investmentProperty {
  @include displayFlex(null, center, column);

  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewDealTitle(36px);
    font-weight: 500;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__cards {
    margin-top: 50px;
    @include displayFlex(center);
    gap: 10px;
    flex-wrap: wrap;

    @include maxW_425 {
      flex-direction: column;
    }
  }

  &__form {
    width: 100%;
    margin-top: 30px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    max-width: 620px;
    width: 100%;
    padding-top: 30px;
    margin: 0 auto;
    gap: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);

    @include maxW_675 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__container {
    @include displayFlex(center, null, column);
    gap: 10px;
  }

  @include maxW_425 {
    width: 280px;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }

  &__label {
    font: 500 14px $main-font;
    color: $main-white;
  }

  &__datePicker {
    height: 40px;
    padding: 0 14px;
    background: none;
    border: 1px solid $main-white;
    border-radius: 6px;
    color: $main-white;
    font: 500 14px $main-font;
    outline: none;
    text-transform: uppercase;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(1);
  }

  &__input {
    margin-top: 5px;
  }
}
