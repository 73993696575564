@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.sponsorProfile {
  &__label {
    display: flex;
    align-items: center;
    color: $text-grey;
    font-size: 14px;
    margin-bottom: 5px;

    @include maxW_425 {
      display: block;
    }
  }

  &__type {
    @include displayFlex(null, center);
    font-size: 14px;
    gap: 10px;
    margin-top: 10px;
  }

  &__link {
    color: $main-blue;
    font-size: 12px;
  }

  &__groupTitle {
    margin: 30px 0 20px;
    color: $light-blue;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  &__unit {
    font-size: 14px;
    line-height: 14px;
    padding: 14px 0 14px 5px;
    color: $text-grey;

    @include maxW_425 {
      margin-right: 30px;
    }
  }

  &__saved {
    margin-top: 20px;
    font-size: 16px;
    font-style: italic;
    color: $text-grey;
  }

  &__mobile {
    width: 290px;
    margin-right: 30px;

    @include maxW_425 {
      width: 150px;
    }
  }

  &__text {
    display: block;
    margin-top: 15px;
    font-size: 16px;
    color: $custom-input-label;
  }
}
