@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Sources {
  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: $text-grey;
    font-size: 14px;
  }

  &__groupTitle {
    margin-bottom: 20px;
    color: $light-blue;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &__datePicker {
    height: 40px;
    padding: 0 14px;
    background: none;
    border: 1px solid $custom-input-border;
    border-radius: 6px;
    font: 500 14px $main-font;
    outline: none;
    color: $main-black;
    text-transform: uppercase;
    position: relative;
  }

  &__dateError {
    position: absolute;
    margin-top: 5px;
    font-size: 14px;
    color: red
  }

  &__groupBtn {
    padding-left: 0;
    margin-top: 12px;
    border: 0;
    color: $main-blue;
    font-size: 14px;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__groupDivider {
    margin: 12px 4px 0;
    background-color: $custom-input-border;
    height: 14px;
    width: 1px;
  }

  &__total {
    font-size: 14px;
    font-weight: bold;
  }

  &__alert {
    font-size: 14px;
    font-weight: 600;
    color: $green-text;
  }
}
